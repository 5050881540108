import React from "react";
import { Link } from "react-router-dom";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Parallax from "components/Parallax/Parallax.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
//My imports
import profile from "assets/img/mypics/portrait2.jpg";
import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";
import ProfileProjects from "views/ProfilePage/components/ProfileProjects.jsx";

import { ArrowForwardIos } from "@material-ui/icons";
import ProfileProjectsCards from "./components/ProfileProjectsCards";

class ProfilePage extends React.Component {
  render() {
    //adds css classes
    const { classes, ...rest } = this.props;
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    ///
    // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    return (
      <div>
        <Header
          color="transparent"
          brand="KIDANY BERRIOS"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "dark"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/mypics/pic81.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                  <div className={classes.profile}>
                    <div>
                      <img src={profile} alt="..." className={imageClasses} />
                    </div>
                    <div className={classes.name}>
                      <h2 className={classes.title}>Kidany Berríos-Vázquez</h2>
                      <h4>Full Stack Engineer</h4>
                      <Link to="/Resume">
                        <Button color="success" round>
                          Resume
                          <ArrowForwardIos
                            style={{ marginLeft: "5px" }}
                            className={classes.icons}
                          />
                        </Button>
                      </Link>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <div className={classes.description}>
                <p>
                  I find code development to be a creative outlet with limitless
                  potential that always brings a new challenge. My goal is to
                  build products that have a positive impact in the world and
                  through code we can reach everywhere.
                  <br />
                  <i>With great power comes great responsibility </i> — Ben
                  Parker
                </p>
              </div>

              <GridContainer justify="center">
                <GridItem xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader color="success">Toolbox</CardHeader>
                    <CardBody>
                      <h4> Frameworks and Languages</h4>
                      <p>
                        Javascript, Typescript, Java, Angular, ReactJS,
                        Springboot, NodeJS, ExpressJS, MongoDB, PostgreSQL,
                        Hibernate ORM, AWS
                      </p>
                      <br></br>
                      <h4>IDEs/tools and VCS</h4>
                      <p>
                        VS Code, IntelliJ IDEA, Chrome DevTools, Redux DevTools,
                        Augury, Github, Sourcetree, JIRA
                      </p>
                      <br></br>
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem xs={12} sm={6} md={4}>
                  <Card>
                    <CardHeader color="success">Learning</CardHeader>
                    <CardBody>
                      <h4>In progress </h4>
                      <p>Elasticsearch</p>
                      <h4>Near future</h4>
                      <p>Vue.js, AWS Lambda, Firebase</p>
                    </CardBody>
                  </Card>
                  <Card style={{ marginTop: "50px" }}>
                    <CardHeader color="success">Hobbies</CardHeader>
                    <CardBody>
                      <span style={{ marginRight: "10px" }}>
                        <i className="fas fa-plane"></i> Travel
                      </span>
                      <span style={{ paddingRight: "10px" }}>
                        <i className="fas fa-hiking"></i> Hiking
                      </span>
                      <i className="fas fa-dumbbell"></i> Gym
                      <br></br>
                      <div style={{ marginTop: "5px" }}></div>
                      <i className="fas fa-book-reader"></i> Reading (Medium,
                      Dev.to, Reddit)
                    </CardBody>
                  </Card>
                </GridItem>
                <GridItem style={{ marginTop: "50px" }} xs={12} sm={6} md={6}>
                  <div className={classes.profile}>
                    <div className={classes.name}>
                      <h3 className={classes.title}>
                        <i className="fas fa-code"></i> My Projects
                      </h3>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
              <ProfileProjectsCards></ProfileProjectsCards>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

ProfilePage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(profilePageStyle)(ProfilePage);
