//custom CSS
/* eslint-disable react/no-unescaped-entities */
import "../../../assets/css/card.css";

import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Layers from "@material-ui/icons/Layers";
import Widgets from "@material-ui/icons/Widgets";
import WebIcon from "@material-ui/icons/Web";
import ViewCarousel from "@material-ui/icons/ViewCarousel";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomTabs from "components/CustomTabs/CustomTabs.jsx"
import microservice from "assets/img/mypics/microservice.jpg";
import miniYTB from "assets/img/mypics/minibrowser.jpg";
import pagaMe from "assets/img/mypics/pagame.jpg";
import photoAnalysis from "assets/img/mypics/photoAnalysis.jpg";
import snakeGame from "assets/img/mypics/snakeGame.jpg";
import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";

class ProfileProjectsCards extends React.Component {

    renderTravelPicCard(cardClass) {
        return (
            <article className={cardClass}>
                <header className="card-header">
                    <p>Backend</p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/kidobv/Picture-Fetcher-Microservice"
                    >
                        <h2><i className={"fab fa-github"} /> Travel pictures</h2>
                    </a>
                    <img src={microservice} alt="Card-img-cap"/>
                    <h3>Fetch some of my favorite travel pictures given a location parameter</h3>
                </header>

                <div className="tags">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success btn-sm"
                        href="https://picsfetcher-microservice.herokuapp.com/"
                    >
                        Demo
                    </a>
                </div>
            </article>
        );
    }
    renderPhotoAnalysisCard(cardClass) {
        return (
            <article className={cardClass}>
                <header className="card-header">
                    <p>Frontend</p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/kidobv/react_photoAnalysis"
                    >
                        <h2><i className={"fab fa-github"} /> Photo Analysis</h2>
                    </a>
                    <img src={photoAnalysis} alt="Card-img-cap" />
                    <h3>Using unsplash and azure computer vision APIs this app to fetch and
                                analyze pictures of a given topic.</h3>
                </header>

                <div className="tags">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success btn-sm"
                        href="https://kidobv.github.io/react_photoAnalysis/"
                    >
                        Demo
                    </a>
                </div>
            </article>
        );
    }
    renderPagaMeCard(cardClass) {
        return (   
            <article className={cardClass}>
                <header className="card-header">
                    <p>Full Stack</p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/kidobv/PagaMe_MERN"
                    >
                        <h2><i className={"fab fa-github"} /> PagaMe App</h2>
                    </a>
                    <img src={pagaMe} alt="Card-img-cap" />
                    <h3>Track and request expenses with friends</h3>
                </header>

                <div className="tags">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success btn-sm"
                        href="http://www.pagame.kbvapps.com:3000/login"
                    >
                        Demo
                    </a>
                </div>
            </article>        
        );
    }
    renderYoutubeBrwsCard(cardClass) {
        return (
            <article className={cardClass}>
                <header className="card-header">
                    <p>Front End</p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/kidobv/Minimalistic_YTBrowser"
                    >
                        <h2><i className={"fab fa-github"} /> Mini Youtube</h2>
                    </a>
                    <img src={miniYTB} alt="Card-img-cap"/>
                    <h3>Browse videos using Youtube API v3</h3>
                </header>

                <div className="tags">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success btn-sm"
                        href="https://kidobv.github.io/Minimalistic_YTBrowser/"
                    >
                        Demo
                    </a>
                </div>
            </article>
        );
    }
    renderSnakeGameCard(cardClass) {
        return ( 
            <article className={cardClass}>
                <header className="card-header">
                    <p>Vanilla JS</p>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://github.com/kidobv/snakeGame"
                    >
                        <h2><i className={"fab fa-github"} /> Snake Game</h2>
                    </a>
                    <img src={snakeGame} alt="Card-img-cap"/>
                    <h3>Classic Snake Game</h3>
                </header>

                <div className="tags">
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-success btn-sm"
                        href="https://kidobv.github.io/snakeGame/"
                    >
                        Demo
                    </a>
                </div>
            </article>           
        );
    }

    render() {
        //adds css classes
        const { classes } = this.props;
        return (
            <div id="projects">
                <GridContainer>
                    <GridItem xs={12} sm={12} md={10} className={classes.navWrapper}>
                        <CustomTabs
                            headerColor="success"
                            tabs={[
                                {
                                    tabName: "All",
                                    tabIcon: Widgets,
                                    tabContent: (
                                        <div id="projects">
                                            <section className="card-list1">
                                                {this.renderPagaMeCard("card1")}
                                                {this.renderTravelPicCard("card1")}
                                                {this.renderPhotoAnalysisCard("card1")}
                                                {this.renderYoutubeBrwsCard("card1")}
                                                {this.renderSnakeGameCard("card1")}
                                            </section>
                                        </div>
                                    )
                                },
                                {
                                    tabName: "Card View",
                                    tabIcon: ViewCarousel,
                                    tabContent: (
                                        <div id="projects">
                                            <section className="card-list">
                                                {this.renderPagaMeCard("card")}
                                                {this.renderTravelPicCard("card")}
                                                {this.renderPhotoAnalysisCard("card")}
                                                {this.renderYoutubeBrwsCard("card")}
                                                {this.renderSnakeGameCard("card")}
                                            </section>
                                        </div>
                                    )
                                },
                                {
                                    tabName: "Front-End",
                                    tabIcon: WebIcon,
                                    tabContent: (
                                        <div id="projects">
                                            <section className="card-list1">
                                                {this.renderPagaMeCard("card1")}
                                                {this.renderPhotoAnalysisCard("card1")}
                                                {this.renderYoutubeBrwsCard("card1")}
                                                {this.renderSnakeGameCard("card1")}
                                            </section>
                                        </div>
                                    )
                                },
                                {
                                    tabName: "Back-End",
                                    tabIcon: Layers,
                                    tabContent: (
                                        <div id="projects">
                                            <section className="card-list1">
                                                {this.renderPagaMeCard("card1")}
                                                {this.renderTravelPicCard("card1")}
                                            </section>
                                        </div>
                                    )
                                }
                            ]} />
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

ProfileProjectsCards.propTypes = {
    classNamees: PropTypes.object
};

export default withStyles(profilePageStyle)(ProfileProjectsCards);
