/* eslint-disable react/no-unescaped-entities */
import "../../assets/css/bootstrap.css";
import "../../assets/css/main.css";
import "../../assets/css/font-awesome.css";


import React from "react";
import { Link } from "react-router-dom";
import Button from "components/CustomButtons/Button.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import { ArrowBackIos, CloudDownloadTwoTone } from "@material-ui/icons";
import PropTypes from "prop-types";

import profile from "assets/img/mypics/portrait2.jpg";

class Resume extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <div id="photo-header" className="text-center">
              <div id="photo" className="portrait">
                <img src={profile} alt="portrait" />
              </div>
              <div id="text-header">
                <h2>Kidany Berríos-Vázquez</h2>
                <h3>
                  <span>Full Stack Engineer</span>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div id="header-row" className="row">
          <div className="col-xs-5 col-sm-2 col-md-2">
            <Link to="/">
              <Button color="success" simple>
                <ArrowBackIos className={classes.icons} />
                Portfolio
              </Button>
            </Link>
          </div>
          <div className="col-xs-2 col-sm-7 col-md-8"></div>
          <div className="col-xs-5 col-sm-2 col-md-2">
            <Button
              href="https://drive.google.com/file/d/1AUY-m7iR6KWf-eI_CVWD-KkFvq0ulw5K/view?usp=sharing"
              color="success"
              simple
            >
              <CloudDownloadTwoTone className={classes.icons} />
              Download
            </Button>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-7">
            {/* <!-- ABOUT ME --> */}
            <div className="box">
              <h2>About Me</h2>
              <p>
                Aspiring to join a team that develops solutions for challenging
                problems through innovation that will drive personal and
                professional growth. Passionate about technology, continuous
                learning, and the web development world.
              </p>
            </div>
            {/* <!-- Experiences --> */}
            <div className="box">
              <h2>Experiences</h2>
              <ul id="education" className="clearfix">
                <li>
                  <div className="year pull-left">2020</div>
                  <div className="description pull-right">
                    <h3>Software Engineering Specialist - Full Stack</h3>
                    Accenture Federal Services - Arlignton, VA (12/2019 – Present)
                    <ul>
                      <li>
                        <p>
                          Monitor and solve issues regarding quality gate
                          compliance with SonarQube. 
                        </p>
                      </li>
                      <li>
                        <p>
                          User story sizing and analysis to find potential
                          dependencies prior to the development phase.
                        </p>
                      </li>
                      <li>
                        <p>
                          Develop new front-end features using Angular
                          components with a focus on reusability and UX to provide
                          the best experience to the 1.6 million average users per
                          month.
                        </p>
                      </li>
                      <li>
                        <p>
                          Update Search microservice to implement new API
                          requirements such as adding new Elastic indexes, validate user roles to serve sensitive data, and enabling
                          new data filtering capabilities.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="year pull-left">2019</div>
                  <div className="description pull-right">
                    <h3>System Engineer - RESTful services with PEGA 7</h3>
                    Infosys (Verizon Contract) - Tampa, FL (01/2019 – Present)
                    <ul>
                      <li>
                        <p>
                          Develop Strategy features for validation and
                          qualification of new and existing products/offers,
                          such as the introduction of YouTube TV as a service
                          for broadband customers, as part of Verizon's
                          (Customer Relationship Management) system application
                          for Fios products.
                        </p>
                      </li>
                      <li>
                        <p>
                          Support RESTful API services consumed by Verizon.com
                          and Verizon Retail systems including new requirements
                          implementation, issue tracking, testing and debugging.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="year pull-left">2018</div>
                  <div className="description pull-right">
                    <h3>Full Stack developer / AngularJS and .Net</h3>Verizon -
                    Tampa, FL (06/2017 – 12/2018)
                    <ul>
                      <li>
                        <p>
                          Designed and implemented a web application that
                          servers as a visualization tool for our Verizon
                          marketing and business teams on Verizon Fios products
                          (Internet, TV, Voice) catalog information.
                          Facilitating the price Facilitating the price
                          calculation of bundle combinations by creating a
                          pricing grid with source data updated via MFT and
                          Jenkins.
                        </p>
                      </li>
                      <li>
                        <p>
                          Integrated Verizon's SSO authentication granting
                          access to members of different teams.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="year pull-left">2017</div>
                  <div className="description pull-right">
                    <h3>Software developer in C#</h3>Verizon - Tampa, FL
                    (03/2016 – 05/2017)
                    <ul>
                      <li>
                        <p>
                          Automated new rule generation process and migrated
                          existing rules for Verizon Product Catalog validation
                          engine from Microsoft BizTalk .net API to a SQL stored
                          procedures rule driven approach, reducing the auditing
                          process time by 65% on average, and eliminating
                          licensing cost of $160K per year.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <div className="year pull-left">2016</div>
                  <div className="description pull-right">
                    <h3>Front-End Web Developer - AngularJS</h3>UPRM Capstone
                    (08/2015 – 12/2015)
                    <ul>
                      <li>
                        <p>
                          Designed and implemented USYMS a Web-based application
                          that provides tools to handle syllabus creation and
                          approval workflow for the UPRM Engineering faculty.
                        </p>
                      </li>
                      <li>
                        <p>
                          Developed features such as: user authentication,
                          mobile support, email and in-app notifications, file
                          version control, workflow definition support, and PDF
                          file rendering.
                        </p>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xs-12 col-sm-5">
           {/* <!-- Technical skills --> */}
            <div className="box">
              <h2>Technical Skills</h2>
              <div className="job clearfix">
                <div className="col-xs-3">
                  <div className="where">Frameworks Languages</div>
                </div>
                <div className="col-xs-9">
                  <div className="description">
                    Javascript, Typescript, Java, C#, Angular, React, Spring Boot, 
                    Hibernate ORM, NodeJS, RxJS, Elasticsearch, Jasmine+Karma
                  </div>
                </div>
              </div>
              <div className="job clearfix">
                <div className="col-xs-3">
                  <div className="where">Database Management</div>
                </div>
                <div className="col-xs-9">
                  <div className="description">
                    PostgreSQL, MongoDB, SQL server
                  </div>
                </div>
              </div>
              <div className="job clearfix">
                <div className="col-xs-3">
                  <div className="where">DevOps CI/CD</div>
                </div>
                <div className="col-xs-9">
                  <div className="description">
                    Jenkins, Docker, SonarQube, Artifactory, JIRA
                  </div>
                </div>
              </div>
              <div className="job clearfix">
                <div className="col-xs-3">
                  <div className="where">IDEs/tools and VCS</div>
                </div>
                <div className="col-xs-9">
                  <div className="description">                    
                    Chrome DevTools, Redux DevTools, VS Code, IntelliJ IDEA,
                    Augury, Github, Sourcetree
                  </div>
                </div>
              </div>
              <div className="job clearfix">
                <div className="col-xs-3">
                  <div className="where">Cloud Services</div>
                </div>
                <div className="col-xs-9">
                  <div className="description">
                    MongoDB Atlas, AWS: S3, EC2, EB, CloudFront, Route53
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- SKILLS --> */}
            {/* <div className="box">
              <h2>Core Skills</h2>
              <div className="skills">
                <div className="item-skills" style={{ width: "100%" }}>
                  OOP
                </div>
                <div className="item-skills" style={{ width: "80%" }}>
                  Angular
                </div>
                <div className="item-skills" style={{ width: "75%" }}>
                  ReactJS
                </div>
                <div className="item-skills" style={{ width: "50%" }}>
                  SQL
                </div>
                <div className="item-skills" style={{ width: "30%" }}>
                  SpringBoot
                </div>
                <div className="skills-legend clearfix">
                  <div className="legend-right legend">1 year</div>
                  <div className="legend-right legend">
                    <span>2 years</span>
                  </div>
                  <div className="legend-right legend">
                    <span>3 years</span>
                  </div>
                  <div className="legend-right legend">4 years</div>
                </div>
              </div>
            </div> */}
            <div className="box clearfix">
              <h2>Project Management</h2>
              <div className="col-xs-12">
                <h5>
                  Following Scrum methodology my responsibilities include:
                </h5>
                <ul> 
                  <li>Sprint planning and user stories definition with JIRA</li>
                  <li>Issue tracking and resolution with JIRA</li>
                  <li>Project design documentation on GSuite and Confluence</li>
                  <li>Daily updates on stand-up meetings</li>
                  <li>Bi-weekly production deployment support</li>
                  <li>Weekly status updates to POs and stakeholders</li>
                </ul>
              </div>
            </div>
            {/* <!-- HOBBIES --> */}
            <div className="box">
              <h2>Core skills</h2>
              <div className="hobby">Angular</div>
              <div className="hobby">RxJS</div>
              <div className="hobby">OOP</div>
              <div className="hobby">ReactJS</div>
              <div className="hobby">REST API</div>
              <div className="hobby">Java</div>
              <div className="hobby">SpringBoot</div>
              <div className="hobby">Elasticsearch</div>              
              <div className="hobby">NodeJS</div>
              <div className="hobby">MongoDB</div>
              <div className="hobby">PostgreSQL</div>
            </div>
            {/* <!-- Education --> */}
            <div className="box">
              <h2>Education</h2>
              <div className="job clearfix">
                <div className="col-xs-12">
                  <div className="profession">
                    BS in Computer Engineering<br></br>
                    University of Puerto Rico Mayaguez
                  </div>
                  <div className="description">08/2009 - 12/2015</div>
                </div>
              </div>
            </div>
            {/* <!-- CONTACT --> */}
            <div className="box clearfix">
              <h2>Contact</h2>
              <div className="contact-item">
                <div className="icon pull-left text-center">
                  <span className="fa fa-phone fa-fw"></span>
                </div>
                <div className="title only pull-right">813-293-1250</div>
              </div>
              <div className="contact-item">
                <div className="icon pull-left text-center">
                  <span className="fa fa-envelope fa-fw"></span>
                </div>
                <div className="title only pull-right">
                  <a href="mailto:kidany.berrios@gmail.com">
                    kidany.berrios@gmail.com
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <div className="icon pull-left text-center">
                  <span className="fa fa-linkedin fa-fw"></span>
                </div>
                <div className="title pull-right">LinkedIn</div>
                <div className="description pull-right">
                  <a href="https://www.linkedin.com/in/kidany-berrios">
                    https://linkedin.com/in/kidany-berrios
                  </a>
                </div>
              </div>
              <div className="contact-item">
                <div className="icon pull-left text-center">
                  <span className="fa fa-github fa-fw"></span>
                </div>
                <div className="title pull-right">Github</div>
                <div className="description pull-right">
                  <a href="https://github.com/kidobv/">
                    https://github.com/kidobv/
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
Resume.propTypes = {
  classes: PropTypes.object
};
export default withStyles(null)(Resume);
