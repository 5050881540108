/*!
=========================================================
*Built by Kidany Berrios with Material Kit React - Copyright 2019 Creative Tim (https://www.creative-tim.com)
=========================================================
*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.7.0";

// pages for this product
import ResumePage from "views/ProfilePage/Resume.jsx";
import ProfilePage from "views/ProfilePage/ProfilePage.jsx";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Route path="/resume" exact component={ResumePage} />
    <Route path="/" exact component={ProfilePage} />
  </Router>,
  document.getElementById("root")
);
