import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
import Layers from "@material-ui/icons/Layers";
import Widgets from "@material-ui/icons/Widgets";
import WebIcon from "@material-ui/icons/Web";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import NavPills from "components/NavPills/NavPills.jsx";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import microservice from "assets/img/mypics/microservice.jpg";
import miniYTB from "assets/img/mypics/minibrowser.jpg";
import pagaMe from "assets/img/mypics/pagame.jpg";
import photoAnalysis from "assets/img/mypics/photoAnalysis.jpg";
import snakeGame from "assets/img/mypics/snakeGame.jpg";
import profilePageStyle from "assets/jss/material-kit-react/views/profilePage.jsx";

class ProfileProjects extends React.Component {
  //functions to render all Project Cards since they are used multiple times///////////////////////////
  renderTravelPicCard() {
    const { classes } = this.props;
    return (
      <Card>
        <img
          style={{
            height: "180px",
            width: "100%",
            display: "block"
          }}
          className={classes.imgCardTop}
          src={microservice}
          alt="Card-img-cap"
        />
        <CardBody>
          <h4 className={classes.cardTitle}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kidobv/Picture-Fetcher-Microservice"
            >
              <i className={"fab fa-github"} /> Fetch pictures Microservice
            </a>
          </h4>
          <p style={{
            fontSize: "12px",
          }}>Fetch some of my favorite travel pictures given a location</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
            href="https://picsfetcher-microservice.herokuapp.com/"
          >
            Demo
          </a>
        </CardBody>
      </Card>
    );
  }

  renderPhotoAnalysisCard() {
    const { classes } = this.props;
    return (
      <Card>
        <img
          style={{
            height: "180px",
            width: "100%",
            display: "block"
          }}
          className={classes.imgCardTop}
          src={photoAnalysis}
          alt="Card-img-cap"
        />
        <CardBody>
          <h4 className={classes.cardTitle}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kidobv/react_photoAnalysis"
            >
              <i className={"fab fa-github"} /> Photo Analysis
            </a>
          </h4>
          <p style={{
            fontSize: "12px",
          }}>
            Using unsplash and azure computer vision APIs this app to fetch and
            analyze pictures of a given topic.
          </p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
            href="https://kidobv.github.io/react_photoAnalysis/"
          >
            Demo
          </a>
        </CardBody>
      </Card>
    );
  }

  renderPagaMeCard() {
    const { classes } = this.props;
    return (
      <Card>
        <img
          style={{
            height: "180px",
            width: "100%",
            display: "block"
          }}
          className={classes.imgCardTop}
          src={pagaMe}
          alt="Card-img-cap"
        />
        <CardBody>
          <h4 className={classes.cardTitle}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kidobv/PagaMe_MERN"
            >
              <i className={"fab fa-github"} /> PagaMe App
            </a>
          </h4>
          <p style={{
            fontSize: "12px",
          }}>Track and request expenses with friends.</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
            href="http://www.pagame.kbvapps.com:3000/login"
          >
            Demo
          </a>
        </CardBody>
      </Card>
    );
  }

  renderYoutubeBrwsCard() {
    const { classes } = this.props;
    return (
      <Card>
        <img
          style={{
            height: "180px",
            width: "100%",
            display: "block"
          }}
          className={classes.imgCardTop}
          src={miniYTB}
          alt="Card-img-cap"
        />
        <CardBody>
          <h4 className={classes.cardTitle}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kidobv/Minimalistic_YTBrowser"
            >
              <i className={"fab fa-github"} /> Youtube Mini Browser
            </a>
          </h4>
          <p style={{
            fontSize: "12px",
          }}>Browse videos using Youtube API v3.</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
            href="https://kidobv.github.io/Minimalistic_YTBrowser/"
          >
            Demo
          </a>
        </CardBody>
      </Card>
    );
  }
  renderSnakeGameCard() {
    const { classes } = this.props;
    return (
      <Card>
        <img
          style={{
            height: "180px",
            width: "100%",
            display: "block"
          }}
          className={classes.imgCardTop}
          src={snakeGame}
          alt="Card-img-cap"
        />
        <CardBody>
          <h4 className={classes.cardTitle}>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://github.com/kidobv/snakeGame"
            >
              <i className={"fab fa-github"} /> Snake Game
            </a>
          </h4>
          <p style={{
            fontSize: "12px",
          }}>Classic Snake Game</p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-success btn-sm"
            href="https://kidobv.github.io/snakeGame/"
          >
            Play
          </a>
        </CardBody>
      </Card>
    );
  }
  ///////////////////////////////////////////////////////////////////////////////////////
  render() {
    //adds css classes
    const { classes } = this.props;
    return (
      <div id="projects">
        <GridContainer>
          <GridItem xs={12} sm={12} md={10} className={classes.navWrapper}>
            <NavPills
              alignCenter
              color="success"
              tabs={[
                {
                  tabButton: "ALL",
                  tabIcon: Widgets,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={4}>
                        {this.renderTravelPicCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        {this.renderPhotoAnalysisCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        {this.renderPagaMeCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        {this.renderYoutubeBrwsCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={4}>
                        {this.renderSnakeGameCard()}
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Front-End",
                  tabIcon: WebIcon,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        {this.renderPhotoAnalysisCard()}
                        {this.renderPagaMeCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={5}>
                        {this.renderYoutubeBrwsCard()}
                        {this.renderSnakeGameCard()}
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Back-End",
                  tabIcon: Layers,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={6} md={5}>
                        {this.renderTravelPicCard()}
                      </GridItem>
                      <GridItem xs={12} sm={6} md={5}>
                        {this.renderPagaMeCard()}
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

ProfileProjects.propTypes = {
  classes: PropTypes.object
};

export default withStyles(profilePageStyle)(ProfileProjects);
